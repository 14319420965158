import { useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import {
  useQueryAllTransferRatesQuery,
  Scalars,
  RateSet,
  VehicleClassOverride,
  VehicleClassOverrideLite,
  ZoneType,
  UpsertPlaceInput,
  ClientsByRateResponse,
  CompaniesByRateResponse,
} from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';
import { EditionInitialValuesInterface } from '../components/TransferRates/NewTransferRateFormulary/NewTransferRateFormulary';

export interface TableRate {
  id: string;
  name?: string;
  pointA: string;
  milesRadiusA: number | string;
  pointB: string;
  milesRadiusB: number | string;
  vehicleType: string;
  customers?: string;
  clients?: string;
  companies?: string;
  map: {
    coordinatesA: Scalars['Coordinates'];
    coordinatesB: Scalars['Coordinates'];
  };
  originalObject: DeepPartial<RateSet>;
}

interface useTransferRatesInterface {
  (): {
    transferRates: Array<TableRate>;
    refetchRates: () => void;
  };
}

export const useTransferRates: useTransferRatesInterface = () => {
  const [ratesForTable, setRatesForTable] = useState<TableRate[]>([]);
  const user = useRecoilValue(userAtom);
  const { data: transferRatesPayload, refetch: refetchRates } =
    useQueryAllTransferRatesQuery({
      variables: {
        accountId: user?.accountId,
      },
      fetchPolicy: 'no-cache',
    });

  const updateValues = (): void => {
    refetchRates({ accountId: user?.accountId });
  };

  useEffect(() => {
    const transferRates =
      transferRatesPayload?.rates?.searchTransferRates?.items || [];
    if (transferRates.length) {
      const ratesForTableData = transferRates.map((transferRate) => {
        const { firstLocation, secondLocation } = transferRate.tripRule;
        const { coordinates: coordinatesA } = firstLocation || {
          coordinates: [0, 0],
        };
        const { coordinates: coordinatesB } = secondLocation || {
          coordinates: [0, 0],
        };
        const result: TableRate = {
          id: transferRate?.id || '',
          name: transferRate?.name,
          pointA: transferRate?.tripRule?.firstLocation?.name || '',
          milesRadiusA:
            transferRate?.tripRule?.firstLocation.zoneType === ZoneType.Area
              ? transferRate?.tripRule?.firstLocation?.radiusInKm
              : 'Exact location',
          pointB: transferRate?.tripRule?.secondLocation?.name || '',
          milesRadiusB:
            transferRate?.tripRule?.secondLocation?.zoneType === ZoneType.Area
              ? transferRate?.tripRule?.secondLocation?.radiusInKm
              : 'Exact location',
          vehicleType: transferRate?.rates[0]?.vehicleClassOverrides
            .map((currentVehicleClass) => {
              const {
                amount,
                vehicleClass: { name },
              } = currentVehicleClass;
              return `${name} - $${(+amount).toFixed(2)}`;
            })
            .join(','),
          customers: transferRate?.customers?.length
            ? transferRate.customers
                .map(({ customer }) => customer.name)
                .join(', ')
            : 'All',
          map: {
            coordinatesA: coordinatesA as Scalars['Coordinates'],
            coordinatesB: coordinatesB as Scalars['Coordinates'],
          },
          originalObject: transferRate as DeepPartial<RateSet>,
        };
        return result;
      });
      setRatesForTable(ratesForTableData);
    }
  }, [transferRatesPayload]);

  return {
    transferRates: ratesForTable,
    refetchRates: updateValues,
  };
};

type coordinatesFromLocationToLatLonInputType = UpsertPlaceInput & {
  latitude?: number;
  longitude?: number;
};

export const coordinatesFromLocationToLatLon = (
  location: coordinatesFromLocationToLatLonInputType,
): { latitude: number; longitude: number } => {
  try {
    if (!location) {
      return { latitude: undefined, longitude: undefined };
    }
    if (location.latitude && location.longitude) {
      return { latitude: location.latitude, longitude: location.longitude };
    }
    const latitude = location.coordinates.coordinates?.length
      ? location.coordinates.coordinates[1]
      : undefined;
    const longitude = location.coordinates.coordinates?.length
      ? location.coordinates.coordinates[0]
      : undefined;

    return { latitude, longitude };
  } catch (error) {
    return { latitude: undefined, longitude: undefined };
  }
};

export const prepareEditInitialValues = (
  originalObject: RateSet,
  transferRateClients: DeepPartial<ClientsByRateResponse>[],
  transferRateCompanies: DeepPartial<CompaniesByRateResponse>[],
): EditionInitialValuesInterface => {
  const formatVehicleOverrides = (
    array: Array<VehicleClassOverride>,
  ): Array<VehicleClassOverrideLite> => {
    return array.map((el) => {
      if (el.id && el.vehicleClass?.id) {
        return {
          id: el.id,
          vehicleClassId: el.vehicleClass?.id,
          amount: el.amount,
        };
      }
      return null;
    });
  };

  const companies = transferRateCompanies?.find(
    (rateCompanies) => rateCompanies?.rateSetId === originalObject?.id,
  );

  const clients = transferRateClients?.find(
    (rateClients) => rateClients?.rateSetId === originalObject?.id,
  );

  return {
    id: originalObject.id,
    name: originalObject?.name,
    firstLocation: {
      placeName: originalObject.tripRule?.firstLocation?.name,
      coordinates: originalObject.tripRule?.firstLocation?.coordinates,
    },
    secondLocation: {
      placeName: originalObject.tripRule?.secondLocation.name,
      coordinates: originalObject.tripRule?.secondLocation.coordinates,
    },
    firstLocationZoneType: originalObject.tripRule?.firstLocation?.zoneType,
    firstLocationRadiusInKm: originalObject.tripRule?.firstLocation?.radiusInKm,
    firstLocationGeoType: originalObject.tripRule?.firstLocation?.geoType,
    firstLocationMatchType: originalObject.tripRule?.firstLocationMatchType,
    secondLocationZoneType: originalObject.tripRule?.secondLocation?.zoneType,
    secondLocationRadiusInKm:
      originalObject.tripRule?.secondLocation?.radiusInKm,
    secondLocationGeoType: originalObject.tripRule?.secondLocation?.geoType,
    secondLocationMatchType: originalObject.tripRule?.secondLocationMatchType,
    vehicleClassOverride:
      originalObject.rates.length &&
      formatVehicleOverrides(originalObject.rates[0].vehicleClassOverrides),
    maxStops: originalObject.tripRule?.maxStops,
    tripRuleId: originalObject.tripRule?.id,
    firstLocationId: originalObject.tripRule?.firstLocation?.id,
    secondLocationId: originalObject.tripRule?.secondLocation?.id,
    customers: originalObject.customers.map(({ customer }) => ({
      id: customer.id,
      name: customer.name,
    })),
    clients:
      clients?.clients?.map((client) => ({
        id: client?.id,
        name: client?.name,
      })) || [],
    companies:
      companies?.companies?.map((company) => ({
        id: company?.id,
        name: company?.name,
      })) || [],
  };
};
